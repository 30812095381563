<template>
  <div id="subscription-experience">
    <KnowledgeExperience
      title='subscription.experiences.title'
      subtitle='subscription.experiences.subtitle'
      :editable="false"
      @next="next"
      @previous="cancelSubscription"
      :loading="loading"
    />
    <Modal
      :dialog="isModalOpen"
      @close="closeModal"
      noPadding
    >
      <template v-slot:content>
        <p class="defaultText--text mt-5">
          {{ $t('subscription.experiences.modal.desc') }}
        </p>
        <CardNav
          :nextText="$t('subscription.experiences.modal.yes')"
          :prevText="$t('subscription.experiences.modal.no')"
          @next="editInvestmentSurvey"
          @previous="closeModal"
          class="mt-5"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import Modal from '@/components/Shared/Modal.vue';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'Experiences',
  components: {
    KnowledgeExperience: lazyLoad('components/Onboarding/KnowledgeExperience'),
    Modal,
    CardNav,
  },
  data() {
    return {
      loading: false,
      isModalOpen: false,
    };
  },
  methods: {
    ...mapActions('subscription', [
      'nextStep',
      'confirmInvestmentSurvey',
    ]),
    async next() {
      this.loading = true;
      try {
        await this.confirmInvestmentSurvey();
        this.nextStep(1);
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
    },
    cancelSubscription() {
      this.isModalOpen = true;
    },
    editInvestmentSurvey() {
      this.$notify.error(this.$t('subscription.experiences.abort'));
      this.$router.push({ name: 'Settings.KnowledgeExperience' });
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 600px) {
  #subscription-experience {
    .v-card__title {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
